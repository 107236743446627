// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/aboutUs.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-branch-tsx": () => import("./../../../src/pages/branch.tsx" /* webpackChunkName: "component---src-pages-branch-tsx" */),
  "component---src-pages-contents-tsx": () => import("./../../../src/pages/contents.tsx" /* webpackChunkName: "component---src-pages-contents-tsx" */),
  "component---src-pages-franchise-tsx": () => import("./../../../src/pages/franchise.tsx" /* webpackChunkName: "component---src-pages-franchise-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-popup-answer-tsx": () => import("./../../../src/pages/popup-answer.tsx" /* webpackChunkName: "component---src-pages-popup-answer-tsx" */),
  "component---src-pages-space-tsx": () => import("./../../../src/pages/space.tsx" /* webpackChunkName: "component---src-pages-space-tsx" */)
}

